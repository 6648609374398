import './BaseLayout.css'
import Header from "./Header/Header";
import Footer from "./Footer/Footer";

function BaseLayout({ children }) {
    return (
        <>
            <Header />
            <main className="main-baseLayout"> {/* Thêm margin trái để nội dung không bị che bởi header */}
                {children}
            </main>
            <Footer />
        </>
    );
}

export default BaseLayout;