import './Header.css'

function Header() {
    return (
        <header className="fixed top-0 left-0 bg-gray-800 text-white h-full flex p-4 header-layout">
            <nav className='navHeader'>
                <div className="mt-2 iconWebHeader"><a href="/" >Blog</a></div>
                <ul className="mt-4 space-y-2">
                    <li><a href="home" className="hover:text-gray-400">Home</a></li>
                    <li><a href="about" className="hover:text-gray-400">About</a></li>
                    <li><a href="contact" className="hover:text-gray-400">Contact</a></li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;