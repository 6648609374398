import { BrowserRouter, Route, Routes } from "react-router-dom";
import BaseLayout from './components/Layout/BaseLayout/BaseLayout';
import Home from './components/Page/Home/Home';
import Login from './components/Page/Login/Login';

function App() {
  return (
    <BrowserRouter >
      <BaseLayout>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/login' element={<Login />} />
        </Routes>
      </BaseLayout>
    </BrowserRouter >
  );
}

export default App;
